import React from 'react'
import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { BannerProps } from 'components/flex/Banner/Shell'
import Plaatjie from '@ubo/plaatjie'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
      margin-bottom: 1rem;
    }
  }

  & p strong {
    font-size: 18px;
  }
`

const Image = styled(Plaatjie)`
  height: 100%;
  object-fit: cover;
`

const BannerDetail: React.FC<BannerProps> = ({ fields }) => (
  <section className="py-lg-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            <div className="col-lg-7 order-lg-2 mb-5 mb-lg-0 position-relative">
              <Image image={fields.image} alt="" />
            </div>
            <div className="col-lg-5 order-lg-1 d-flex flex-column align-items-center">
              <Content content={fields.description} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default BannerDetail
