import React from 'react'
import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { BannerProps } from 'components/flex/Banner/Shell'

const VideoWrapper = styled.div`
  @media (max-width: 991px) {
    padding-top: 56%;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
      margin-bottom: 1rem;
    }
  }

  & p strong {
    font-size: 18px;
  }
`

const BannerVideo: React.FC<BannerProps> = ({ fields }) => (
  <section className="mb-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            <div className="col-lg-7 order-lg-2 mb-5 mb-lg-0 position-relative">
              <VideoWrapper>
                <iframe
                  src={fields.videosrc}
                  title="Banner video"
                  frameBorder={0}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </VideoWrapper>
            </div>
            <div className="col-lg-5 order-lg-1 d-flex flex-column align-items-center">
              <Content content={fields.description} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default BannerVideo
