import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { BannerProps } from 'components/flex/Banner/Shell'

const Image = styled(Plaatjie)`
  width: 293px;
  height: 268px;
`

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & h1 {
      font-size: 50px;
    }

    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 20px !important;
    }
  }
`

const BannerDefault: React.FC<BannerProps> = ({ fields }) => (
  <section className="mb-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            <div className="col-md-5 d-flex flex-column align-items-center">
              <Image image={fields.icon} className="mb-5" alt="" />
              <ParseContent content={fields.cta} />
            </div>
            <div className="col-md-7 mt-5 mt-md-0">
              <Content content={fields.description} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default BannerDefault
